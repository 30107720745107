// Footer.jsx
import React from 'react';
import './Footer.css';

const Footer = () => {
  return (
    <div className="footer">
      <div className="footer-content">
        <div className="contact-info">support@modol.vip</div>
        <span className="contact-info">沪ICP备2023026663号</span>
        <div className="rights-info">
          <span claassName="copyright-info">
            {new Date().getFullYear()} 摩态科技（上海）有限公司
          </span>
          <span className="all-rights">© All rights reserved.</span>
        </div>
      </div>
    </div>
  );
};

export default Footer;
