import React from 'react';

const UserQuota = ({ remainingQuota }) => {
  return (
    <span className="user-quota">剩余额度：{remainingQuota} 次</span>
  );
};

export default UserQuota;

