import React from 'react';
import './Background.css'; // Import the background CSS file

const Background = () => {
  return (
    <div className="area" >
        <ul className="circles">
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
        </ul>
    </div >
  );
};

export default Background;