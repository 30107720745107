import React, { useState, useEffect, useContext, useRef } from 'react';
import './Navbar.css';
import LoginRegisterPopup from '../LoginRegisterPopup/LoginRegisterPopup';
import { AuthContext } from '../AuthContext';
import UserQuota from '../Userquota';

const Navbar = ({remainingQuota}) => {
  const [showPopup, setShowPopup] = useState(false);
  const [userData, setUserData] = useState({});
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const dropdownContainerRef = useRef(null);

  const { isLoggedIn, onLogin, onLogout } = useContext(AuthContext);

  const togglePopup = () => {
    setShowPopup(!showPopup);
  };

  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible);
  };

  const closeDropdown = () => {
    setDropdownVisible(false);
  };

  const handleLogout = () => {
    // Remove items from localStorage when the user logs out
    localStorage.removeItem('accessToken');
    localStorage.removeItem('userName');
    onLogout();
    closeDropdown();
  };

  // useEffect(() => {
  //   const storedUserName = localStorage.getItem('userName');
  //   setUserData({ username: storedUserName }); // Initialize with stored username if available

  //   const handleClick = (event) => {
  //     if (
  //       dropdownContainerRef.current &&
  //       !dropdownContainerRef.current.contains(event.target)
  //     ) {
  //       closeDropdown();
  //     }
  //   };

  //   document.addEventListener('click', handleClick);

  //   return () => {
  //     document.removeEventListener('click', handleClick);
  //   };
  // }, []);

  // useEffect(() => {
  //   if (isLoggedIn) {
  //     // If the user is logged in, make an authenticated API request to get user info
  //     fetchUserInfo();
  //   }
  // }, [isLoggedIn]);

  // // Function to fetch user info using the provided access token
  // const fetchUserInfo = () => {
  //   const accessToken = localStorage.getItem('accessToken'); // Get the access token from localStorage

  //   // Make an authenticated API request to get user info
  //   fetch('https://api.aws.modol.vip:18080/api/v1/users/info', {
  //     method: 'GET',
  //     headers: {
  //       Authorization: `Bearer ${accessToken}`,
  //     },
  //   })
  //     .then((response) => response.json())
  //     .then((data) => {
  //       if (data.code === 200) {
  //         setUserData({ username: data.data.phone_number });
  //       } else {
  //         console.error('Failed to fetch user info:', data.msg);
  //       }
  //     })
  //     .catch((error) => {
  //       console.error('An error occurred while fetching user info:', error);
  //     });
  // };

  return (
    <div>
      <section className="navbar">
        <div className="navbar-container">
          <div className='logo-container'>
            <img src="./logo.png" alt="logo" width={130} />
            <h3>私域 AI 知识助手 · 体验版</h3>
          </div>
          <div className="button-container">
            {isLoggedIn ? (
              <div className="dropdown-container" ref={dropdownContainerRef}>
                <button className="nav-button dropdown-button" onClick={toggleDropdown}>
                  <span>
                    <img src="./profile.svg" alt="profile" width={30} className='profile'/>
                    {/* {userData.phone_number} */}
                  </span>
                </button>
                {dropdownVisible && (
                  <div className="dropdown-box">
                    {/* <span className="user-name">用戶：{userData.username}</span> */}
                    <UserQuota remainingQuota={remainingQuota} />
                    <button className="nav-button signout" onClick={handleLogout}>
                      退出
                    </button>
                  </div>
                )}
              </div>
            ) : (
              <button className="nav-button" onClick={togglePopup}>
                登录/注册
              </button>
            )}
          </div>
        </div>
      </section>
      {showPopup && <LoginRegisterPopup onClose={togglePopup} />}
    </div>
  );
};

export default Navbar;


