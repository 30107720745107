import React, { useState } from 'react';
import './CooperationPopup.css';
import Notification from '../Notification/Notification';

const CooperationPopup = ({ onClose }) => {
  const [formData, setFormData] = useState({
    name: '',
    weChat: '', 
    message: '',
  });

  const [showNotification, setShowNotification] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState('');
  const [notificationType, setNotificationType] = useState('');

  function generateFormVersionId() {
    // Generate a timestamp-based unique ID
    return new Date().getTime().toString();
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formVersionId = generateFormVersionId();

    const requestBody = {
      phone_number: formData.weChat, // WeChat is the phone_number
      content: `${formData.name}: ${formData.message}, Form Version ID: ${formVersionId}`,
    };

    try {
      const response = await fetch('https://api.aws.modol.vip:18080/api/v1/survey/record_survey', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody),
      });

      if (response.ok) {
        setNotificationType('success');
        setNotificationMessage('已成功发送');
        setShowNotification(true);
        setTimeout(() => setShowNotification(false), 1000);
        onClose();
      } else {
        setNotificationType('error');
        setNotificationMessage('发送失败，请稍后再试');
        setShowNotification(true);
        setTimeout(() => setShowNotification(false), 5000);
      }
    } catch (error) {
      setNotificationType('error');
      setNotificationMessage('发送失败，请稍后再试');
      setShowNotification(true);
      setTimeout(() => setShowNotification(false), 5000);
      console.error('Error submitting survey:', error);
    }
  };

  return (
    <div className="overlay">
      <div className="popup">
        <div className="popup-content">
          <h2>合作咨询</h2>
          <form onSubmit={handleSubmit}>
            <label htmlFor="name">姓名:</label>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
            />

            <label htmlFor="weChat">微信号:</label>
            <input
              type="text"
              id="weChat"
              name="weChat"
              value={formData.weChat}
              onChange={handleChange}
              required
            />

            <label htmlFor="message">消息:</label>
            <textarea
              id="message"
              name="message"
              value={formData.message}
              onChange={handleChange}
              required
            ></textarea>

            <button type="submit" className="submit_button">
              提交
            </button>
            <button type="button" className="btn-close" onClick={onClose}>
              X
            </button>
          </form>
          {showNotification && <Notification message={notificationMessage} type={notificationType} />}
        </div>
      </div>
    </div>
  );
};

export default CooperationPopup;
