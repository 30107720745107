import React, { useState, useContext, useEffect, } from 'react';
import './LoginRegisterPopup.css';
import { AuthContext } from '../AuthContext';

const LoginRegisterPopup = ({ onClose }) => {
  const { onLogin } = useContext(AuthContext);

  const [phoneNumberData, setPhoneNumberData] = useState({
    phoneNumber: '',
    verificationCode: '',
  });

  const [captchaData, setCaptchaData] = useState({
    captchaId: '',
    captchaImageUrl: '',
    captchaText: '',
    capchaToken: '',
  });

  const [validatedToken, setValidatedToken] = useState('');
  const [response, setResponse] = useState('');

  const [loginError, setLoginError] = useState(null);

  useEffect(() => {
    requestImageCaptcha();
  }, []); // Load captcha on component mount

  const [isCountingDown, setIsCountingDown] = useState(false);
  const [countdown, setCountdown] = useState(30);

  const startCountdown = () => {
    setIsCountingDown(true);
    setCountdown(30);

    const countdownInterval = setInterval(() => {
      setCountdown((prevCountdown) => prevCountdown - 1);
    }, 1000);

    setTimeout(() => {
      clearInterval(countdownInterval);
      setIsCountingDown(false);
    }, 30000);
  };

  const handleGetVerificationCode = () => {
    if (!isCountingDown) {
      // Only request the code if not already counting down
      requestSmsCode();
      startCountdown();
    }
  };

  const requestImageCaptcha = async () => {
    const apiUrl = "https://api.aws.modol.vip:18080/api/v1/auth/capcha";

    try {
      const response = await fetch(apiUrl);

      if (response.ok) {
        const data = await response.json();
        setCaptchaData({
          captchaId: data.data.captcha_id,
          captchaImageUrl: data.data.capcha_image,
          captchaText: '',
          capchaToken: data.data.capcha_token,
        });
      } else {
        console.error(
          "Request Image Captcha Error:",
          response.status,
          response.statusText
        );
      }
    } catch (error) {
      console.error("An error occurred during image captcha request:", error);
    }
  };

  const handlePhoneInputChange = (e) => {
    const { name, value } = e.target;
    let newPhoneNumber = value;

    if (name === 'phoneNumber') {
      newPhoneNumber = newPhoneNumber.replace(/\D/g, '');

      if (newPhoneNumber.length > 11) {
        newPhoneNumber = newPhoneNumber.slice(0, 11);
      }

      setPhoneNumberData((prevData) => ({
        ...prevData,
        [name]: newPhoneNumber,
      }));
    } else if (name === 'verificationCode') {
      setPhoneNumberData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const requestSmsCode = async () => {
    const apiUrl = "https://api.aws.modol.vip:18080/api/v1/auth/validatephone";

    const requestBody = {
      capcha: {
        capcha_text: captchaData.captchaText,
        capcha_token: captchaData.capchaToken,
        captcha_id: captchaData.captchaId,
      },
      phone_number: phoneNumberData.phoneNumber,
    };

    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(requestBody),
      });

      if (response.ok) {
        const data = await response.json();

        if (data.code === 200 && data.data.validated_token) {
          // Extract the validated_token
          const validatedToken = data.data.validated_token;

          // Use the validatedToken as needed, for example, you can store it in state
          // or pass it to another function
          // console.log("Validated Token:", validatedToken);
          setValidatedToken(validatedToken);
          setResponse("Phone Verification Code Sent");
        } else {
          console.error("Get Phone Captcha Error:", data.msg);
          setResponse("Get Phone Captcha Error: " + data.msg);
        }
      } else {
        console.error(
          "Get Phone Captcha Error:",
          response.status,
          response.statusText
        );
        setResponse(
          "Get Phone Captcha Error: " +
          response.status +
          " " +
          response.statusText
        );
      }
    } catch (error) {
      console.error("An error occurred during Get Phone Captcha:", error);
      setResponse(
        "An error occurred during Get Phone Captcha: " + error.message
      );
    }
  };

  const handleLogin_Phone = async (e) => {
    e.preventDefault();

    const apiUrl = "https://api.aws.modol.vip:18080/api/v1/auth/login-with-phone-code";
    const requestBody = {
      phone_number: phoneNumberData.phoneNumber,
      phone_text_id: 0,
      phone_text: phoneNumberData.verificationCode,
      validated_token: validatedToken,
    };

    console.log("Submitting the following data:", requestBody);
    onClose();

    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(requestBody),
      });

      if (response.ok) {
        const data = await response.json();
        console.log("Login Successful", data);
        const accessToken = data.data.access_token;
        
        if (accessToken) {
          localStorage.setItem('accessToken', accessToken);
          onLogin({ username: phoneNumberData.phoneNumber });
        } else {
          console.error("AccessToken not found in response.");
        }
      } else {
        console.error(
          "Phone Login Error:",
          response.status,
          response.statusText
        );
        setLoginError("Phone Login Error: " + response.status + " " + response.statusText);
      }
    } catch (error) {
      console.error("An error occurred during phone login:", error);
      setLoginError("An error occurred during phone login: " + error.message);
    }
  };

  const shouldDisplayPhoneNumberErrorMessage =
    phoneNumberData.phoneNumber.length > 0 && phoneNumberData.phoneNumber.length < 11;

  return (
    <div className="overlay">
      <div className="popup">
        <div className="popup-content">
          <h2>手机快捷登录</h2>
          <form onSubmit={handleLogin_Phone}>
            <div className="phone-number-container">
              <label htmlFor="phoneNumber">手机号</label>
              <input
                type="tel"
                id="phoneNumber"
                name="phoneNumber"
                placeholder="请您输入手机号"
                value={phoneNumberData.phoneNumber}
                onChange={handlePhoneInputChange}
                required
                className="custom-input"
              />
              {shouldDisplayPhoneNumberErrorMessage && (
                <div className="error-message">请输入11位手机号</div>
              )}
            </div>
            <br />
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <>
                <input
                  type="text"
                  id="captchaText"
                  name="captchaText"
                  placeholder='输入图形验证码'
                  value={captchaData.captchaText}
                  onChange={(e) =>
                    setCaptchaData({ ...captchaData, captchaText: e.target.value })
                  }
                  required
                />
                <img src={captchaData.captchaImageUrl} alt="Captcha" className='captchaimage'/>
              </>
              <button onClick={requestImageCaptcha} className='refresh_btn' >
                <img src="Refresh_icon.png" alt="Refresh"  className='refresh'/>
              </button>
            </div>
            <label htmlFor="verificationCode" className="verifi_text">
              验证码
            </label>
            <div className="code-input">
              <input
                type="text"
                id="verificationCode"
                name="verificationCode"
                placeholder="请输入验证码"
                value={phoneNumberData.verificationCode}
                onChange={handlePhoneInputChange}
                required
              />
             <button
                type="button"
                className={`veri_button ${isCountingDown ? 'grayed-button' : ''}`}
                onClick={handleGetVerificationCode}
                disabled={isCountingDown}
              >
                {isCountingDown ? `重新发送(${countdown}s)` : '获取验证码'}
              </button>
            </div>
            <button type="button" className="submit_button" onClick={handleLogin_Phone}>
              登录
            </button>
          </form>
          <button type="button" className="btn-close" onClick={onClose}>
            X
          </button>
        </div>
      </div>
    </div>
  );
};

export default LoginRegisterPopup;


