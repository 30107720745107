import React, { useState ,useEffect  } from 'react';
import { BrowserRouter as Router, Route, Routes }  from 'react-router-dom'; 
import { AuthProvider } from './components/AuthContext';
import Background from './components/Background/Background';
import Hero from './components/Hero/Hero';
import Navbar from './components/Navbar/Navbar';
import Chat from './components/Chat/Chat';
import Footer from './components/Footer/Footer';
import UserQuota from './components/Userquota';
import Admin from './components/Admin/Admin'; 
import "./App.css";

function App() {
  const [remainingQuota, setRemainingQuota] = useState(0);

  const fetchUserChatUsage = async () => {
    // Include the access token from localStorage in the request headers
    const accessToken = localStorage.getItem('accessToken');

    try {
      const response = await fetch('https://api.aws.modol.vip:18080/api/v1/users/chat_usage', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (response.ok) {
        const data = await response.json();
        if (data.code === 200) {
          const chatUsage = data.data.chat_balances;
          const updatedRemainingQuota = chatUsage;
          setRemainingQuota(updatedRemainingQuota);
        } else {
          console.error('Failed to fetch user chat usage:', data.msg);
        }
      } else {
        console.error('Failed to fetch user chat usage:', response.statusText);
      }
    } catch (error) {
      console.error('An error occurred while fetching user chat usage:', error);
    }
  };

  const updateRemainingQuota = async () => {
    // Fetch and update the latest user's chat usage data
    await fetchUserChatUsage();
  };

  useEffect(() => {
    // Fetch and update the user's chat usage when the component mounts
    fetchUserChatUsage();
  }, []);

  return (
    <AuthProvider updateQuota={updateRemainingQuota}>
         <Router>
        <div className="App">
          <Background />
          <Navbar remainingQuota={remainingQuota} />
          <Routes> {/* 'Routes' component replaces 'Switch' */}
            {/* Route for the main application, notice the use of 'element' prop */}
            <Route
              path="/"
              element={
                <>
                  <div className="main-content">
                    <Hero />
                    <Chat remainingQuota={remainingQuota} updateRemainingQuota={updateRemainingQuota} />
                  </div>
                  <Footer className="footer" />
                  <UserQuota remainingQuota={remainingQuota} />
                </>
              }
            />
            {/* Route for the admin panel, using 'element' prop with component */}
            <Route path="/admin" element={<Admin />} /> {/* 'element' prop with the component instance */}
          </Routes>
        </div>
      </Router>
    </AuthProvider>
  );
}

export default App;


