import React from 'react';
import './Notification.css';

const Notification = ({ message, type }) => {
  const notificationClass = type === 'error' ? 'error-notification' : 'success-notification';

  return (
    <div className={notificationClass}>
      {message}
    </div>
  );
};

export default Notification;
