

import React, { useState } from 'react';
import './Admin.css';

const Admin = () => {
  const [accessToken, setAccessToken] = useState(null);
  const [formData, setFormData] = useState({ username: '', password: '' });
  const [surveyData, setSurveyData] = useState(null);
  const [userData, setUserData] = useState(null);
  const [rechargeCreditsData, setRechargeCreditsData] = useState({
    uid: '',
    creditAdd: '',
  });
  const [notification, setNotification] = useState(null);
  const [visibleSection, setVisibleSection] = useState(null);
  const [chatUsageUserId, setChatUsageUserId] = useState('');
  const [chatUsageData, setChatUsageData] = useState(null);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleRechargeCreditsInputChange = (e) => {
    const { name, value } = e.target;
    setRechargeCreditsData({
      ...rechargeCreditsData,
      [name]: value,
    });
  };

  const showSurveyData = () => {
    fetchSurveyData(accessToken);
    setVisibleSection('survey');
  };

  const showUserData = () => {
    fetchUserData();
    setVisibleSection('user');
  };

  const showChatUsageData = () => {
    fetchChatUsageData(chatUsageUserId);
  };

  const handleLogin = async () => {
    const params = new URLSearchParams();
    params.append('grant_type', ''); // Replace with the appropriate grant type if needed
    params.append('username', formData.username);
    params.append('password', formData.password);
    params.append('scope', ''); // Replace with the appropriate scope if needed
    params.append('client_id', ''); // Replace with your client ID if needed
    params.append('client_secret', ''); // Replace with your client secret if needed

    try {
      const response = await fetch('https://api.aws.modol.vip:18080/api/v1/login/access-token', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: params,
      });

      if (response.ok) {
        const data = await response.json();
        const token = data.access_token;
        setAccessToken(token);
      } else {
        const errorData = await response.json();
        console.error('Login failed:', errorData.detail || response.statusText);
      }
    } catch (error) {
      console.error('An error occurred during login:', error);
    }
  };

  const fetchSurveyData = async (accessToken) => {
    try {
      const response = await fetch(`https://api.aws.modol.vip:18080/api/v1/survey/list?skip=0&limit=100`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (response.ok) {
        const surveyData = await response.json();
        setSurveyData(surveyData);
      } else {
        console.error('Failed to fetch survey data:', response.statusText);
      }
    } catch (error) {
      console.error('An error occurred while fetching survey data:', error);
    }
  };

  const fetchUserData = async () => {
    try {
      const response = await fetch(`https://api.aws.modol.vip:18080/api/v1/users/list`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (response.ok) {
        const userData = await response.json();
        setUserData(userData);
      } else {
        console.error('Failed to fetch user data:', response.statusText);
      }
    } catch (error) {
      console.error('An error occurred while fetching user data:', error);
    }
  };

  const fetchChatUsageData = async (userId) => {
    try {
      const response = await fetch(`https://api.aws.modol.vip:18080/api/v1/users/user_chat_usage?user_id=${userId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        // No body field for GET requests
      });
  
      if (response.ok) {
        const chatUsageData = await response.json();
        setChatUsageData(chatUsageData);
        setVisibleSection('chatUsage');
      } else {
        console.error('Failed to fetch chat usage data:', response.statusText);
      }
    } catch (error) {
      console.error('An error occurred while fetching chat usage data:', error);
    }
  };

  const rechargeUserCredits = async () => {
    const requestBody = {
      uid: rechargeCreditsData.uid,
      credit_add: rechargeCreditsData.creditAdd,
    };

    try {
      const response = await fetch(`https://api.aws.modol.vip:18080/api/v1/users/recharge_credits`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify(requestBody),
      });

      if (response.ok) {
        setNotification('Add successful!');
        setTimeout(() => {
          setNotification(null);
        }, 3000); // Hide the notification after 3 seconds
      } else {
        console.error('Failed to recharge user credits:', response.statusText);
      }
    } catch (error) {
      console.error('An error occurred while recharging user credits:', error);
    }
  };

  return (
    <div className='container'>
      <h1 className='adminh1'>Admin Panel</h1>
      {accessToken ? (
        <div>
          <button onClick={showSurveyData} className='adminbutton'>Get Survey</button>
          <button onClick={showUserData} style={{ marginLeft: '10px' }} className='adminbutton'>Get User List</button>
          <div>
            <label>User ID:</label>
            <input type="text" value={chatUsageUserId} onChange={(e) => setChatUsageUserId(e.target.value)} />
            <button onClick={showChatUsageData} className='adminbutton'>Get One User Chat Usage</button>
          </div>
          <div>
            <label>User ID:</label>
            <input type="text" name="uid" value={rechargeCreditsData.uid} onChange={handleRechargeCreditsInputChange} />
            <label>Credits to Add:</label>
            <input type="text" name="creditAdd" value={rechargeCreditsData.creditAdd} onChange={handleRechargeCreditsInputChange} />
            <button onClick={rechargeUserCredits} className='adminbutton'>Recharge User Chat Credits</button>
          </div>
          {notification && (
            <div className="notification">
              {notification}
            </div>
          )}
          {visibleSection === 'chatUsage' && chatUsageData && (
            <div>
              <h2>Get One User Chat Usage</h2>
              <div>
                <label>Chat Number:</label>
                <span>{chatUsageData.data.chat_number}</span>
                <label>Chat Token:</label>
                <span>{chatUsageData.data.chat_token}</span>
                <label>Chat Balances:</label>
                <span>{chatUsageData.data.chat_balances}</span>
              </div>
            </div>
          )}
          {visibleSection === 'survey' && surveyData && (
            <div>
              <h2>Survey Data</h2>
              <pre>{JSON.stringify(surveyData, null, 2)}</pre>
            </div>
          )}
          {visibleSection === 'user' && userData && (
            <div>
              <h2>User Data</h2>
              <pre>{JSON.stringify(userData, null, 2)}</pre>
            </div>
          )}
        </div>
      ) : (
        <div>
          <label>
            Username:
            <input type="text" name="username" value={formData.username} onChange={handleInputChange} />
          </label>
          <br />
          <label>
            Password:
            <input type="password" name="password" value={formData.password} onChange={handleInputChange} />
          </label>
          <br />
          <button onClick={handleLogin} className='adminbutton'>Login</button>
        </div>
      )}
    </div>
  );
};

export default Admin;
